import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useIsFocused } from '@react-navigation/native';
import { View } from 'react-native';
import { getText } from 'assets/localization/localization';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { Text } from 'assets/components/text';
import { IconButton } from 'react-native-paper';
import { CloseIcon } from 'assets/icons';
import {
  NewMessageHandler,
  NewMessageProps,
  SubjectOptionsEnum,
} from './MessageProps';
import { NewMessageForm } from './MessageProps';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout';
import { StoreInformation } from '../../components/store-selector/StoreInformation';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { useAppStateStore } from '../../store/app-store';
import { useUserState } from '../../store/user-store';
import { Button } from 'assets/components/button';
import { StoreSelectorHandler } from '../../components/store-selector/types';
import { StoreSelector } from '../../components/store-selector';
import { NewMessageRadioButton } from './NewMessageRadioButton';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';
import patientService from '../../api/patient-service';
import { ConversationStackParamList } from './ConversationNavigation';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { TextField } from 'assets/components/text-field';
import { MESSAGE_LIMIT } from '../../../../pharmacy/modules/screens/messages/data';

const NewMessageBottomSheetComponent: ForwardRefRenderFunction<
  NewMessageHandler,
  NewMessageProps
> = (
  { handleMessageSend = async () => {}, preSelectedSubject }: NewMessageProps,
  ref: ForwardedRef<NewMessageHandler>,
) => {
  const theme = useTheme();
  const styles = useStyles();
  const sheetRef = React.useRef<BottomSheetModal>(null);
  const { stores } = useAppStateStore();
  const [currentStore, setCurrentStore] = useState<PharmacyLocationDto>();
  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();
  const { user, updateUser } = useUserState();
  const storeId = user?.preferredPharmacyLocationId;
  const isFocused = useIsFocused();
  const storeSelectorRef = useRef<StoreSelectorHandler>(null);

  useEffect(() => {
    setCurrentStore(stores.find((x) => x.id === storeId));
    void (async () => {
      if (user?.patientRecordId) {
        const patientRecordData = await patientService.findPatientRecord(
          user.patientRecordId,
        );
        setPatientRecord(patientRecordData);

        const locationPatientRecord =
          patientRecordData.location_patient_records.find(
            (record) => record.location_id === user.preferredPharmacyLocationId,
          );
        await updateUser({
          preferredPharmacyLprId: locationPatientRecord?.id,
        });
      }
    })();
  }, [storeId, stores, isFocused]);

  const methods = useForm<NewMessageForm>({
    defaultValues: {
      message: '',
      subject: preSelectedSubject
        ? preSelectedSubject
        : SubjectOptionsEnum.General,
    },
  });

  useImperativeHandle(ref, () => ({
    show: () => sheetRef.current?.present(),
    hide: () => sheetRef.current?.dismiss(),
  }));

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
    methods.reset();
  };

  const handleStoreSelectorChange = () => {
    //TODO: figure out how to stop the new message bottom sheet from temporarily closing while the store selector is open
    storeSelectorRef.current?.show();
  };

  const handleOnLocationChanged = async (value: PharmacyLocationDto) => {
    let locationPatientRecordId: string | undefined;

    if (user?.patientRecordId) {
      const patientRecordData = await patientService.findPatientRecord(
        user.patientRecordId,
      );

      locationPatientRecordId = patientRecordData.location_patient_records.find(
        (record) => record.location_id === value.id,
      )?.id;

      await updateUser({
        preferredPharmacyLocationId: value.id,
        preferredPharmacyLprId: locationPatientRecordId,
      });
    }
  };

  const handleOnMessageChange = (value: string) => {
    methods.setValue('message', value);
  };

  return (
    <BottomSheet
      bottomSheetRef={sheetRef}
      height={'90%'}
      headerContent={
        <View style={styles.bottomSheetViewTitle}>
          <View style={styles.locationContainer}>
            <View style={styles.bottomSheetViewTitle}>
              <Text style={styles.title}>{getText('new-message')}</Text>
            </View>
            <View style={styles.closeIcon}>
              <IconButton
                accessibilityLabel={getText('dismiss')}
                icon={CloseIcon}
                onPress={handleBottomSheetDismiss}
                size={20}
                color={theme.palette.gray[500]}
              />
            </View>
          </View>
        </View>
      }
      onDismiss={handleBottomSheetDismiss}
      children={
        <View style={styles.container}>
          <Form methods={methods}>
            {currentStore && (
              <View style={styles.storeInfo}>
                <StoreInformation
                  showPhone={false}
                  showChange={true}
                  item={currentStore}
                  onChangePress={handleStoreSelectorChange}
                />
              </View>
            )}
            <TextField
              name="message"
              multiline={true}
              numberOfLines={5}
              placeholder={getText('new-message-placeholder')}
              onChange={(value) => handleOnMessageChange(value)}
              maxLength={MESSAGE_LIMIT}
              rules={{
                required: getText('message-body-is-required'),
              }}
            />
            <View style={styles.messageLimitWrapper}>
              <Text style={styles.messageLimit}>{`${
                methods.watch('message').length
              }/${MESSAGE_LIMIT}`}</Text>
            </View>
            <Text>{getText('message-subject-label')}</Text>
            <View style={styles.radioButtonsContainer}>
              <>
                <View style={styles.radioButtonsInnerContainerLeft}>
                  <NewMessageRadioButton
                    selected={
                      methods.watch('subject') === SubjectOptionsEnum.General
                    }
                    text={getText('general')}
                    value={SubjectOptionsEnum.General}
                    onPress={() => {
                      methods.setValue('subject', SubjectOptionsEnum.General);
                    }}
                    disabled={
                      preSelectedSubject
                        ? preSelectedSubject !== SubjectOptionsEnum.General
                        : undefined
                    }
                  />
                  <NewMessageRadioButton
                    selected={
                      methods.watch('subject') ===
                      SubjectOptionsEnum.Appointments
                    }
                    text={getText('appointments')}
                    value={SubjectOptionsEnum.Appointments}
                    onPress={() => {
                      methods.setValue(
                        'subject',
                        SubjectOptionsEnum.Appointments,
                      );
                    }}
                    disabled={
                      preSelectedSubject
                        ? preSelectedSubject !== SubjectOptionsEnum.Appointments
                        : undefined
                    }
                  />
                </View>
                <View style={styles.radioButtonsInnerContainerRight}>
                  <NewMessageRadioButton
                    selected={
                      methods.watch('subject') === SubjectOptionsEnum.Billing
                    }
                    text={getText('billing')}
                    value={SubjectOptionsEnum.Billing}
                    onPress={() => {
                      methods.setValue('subject', SubjectOptionsEnum.Billing);
                    }}
                    disabled={
                      preSelectedSubject
                        ? preSelectedSubject !== SubjectOptionsEnum.Billing
                        : undefined
                    }
                  />
                  <NewMessageRadioButton
                    selected={
                      methods.watch('subject') ===
                      SubjectOptionsEnum.SideEffects
                    }
                    text={getText('side-effects')}
                    value={SubjectOptionsEnum.SideEffects}
                    onPress={() => {
                      methods.setValue(
                        'subject',
                        SubjectOptionsEnum.SideEffects,
                      );
                    }}
                    disabled={
                      preSelectedSubject
                        ? preSelectedSubject !== SubjectOptionsEnum.SideEffects
                        : undefined
                    }
                  />
                </View>
              </>
              <NewMessageRadioButton
                selected={
                  methods.watch('subject') === SubjectOptionsEnum.MedicationInfo
                }
                text={getText('med-info')}
                value={SubjectOptionsEnum.MedicationInfo}
                onPress={() => {
                  methods.setValue(
                    'subject',
                    SubjectOptionsEnum.MedicationInfo,
                  );
                }}
                disabled={
                  preSelectedSubject
                    ? preSelectedSubject !== SubjectOptionsEnum.MedicationInfo
                    : undefined
                }
              />
            </View>
            <Form.Actions>
              <View style={styles.actionsContainer}>
                <View style={styles.actionsInnerContainer}>
                  <Button
                    onPress={handleBottomSheetDismiss}
                    hierarchy="secondary"
                    logger={{ id: 'new-message-bottom-sheet-cancel-button' }}
                    style={{
                      marginBottom: theme.getSpacing(1),
                      marginTop: theme.getSpacing(3),
                    }}
                  >
                    {getText('cancel')}
                  </Button>
                </View>
                <View style={styles.buttonContainer}>
                  <Button
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onPress={methods.handleSubmit((data) => {
                      if (user && currentStore && patientRecord) {
                        handleMessageSend(
                          user.pharmacyId,
                          currentStore.id,
                          patientRecord,
                          {
                            author_id: user.id,
                            author_type: AuthorType.Patient,
                            patient_viewed_all_messages: true,
                            pharmacy_viewed_all_messages: false,
                            subject: data.subject,
                            content: data.message,
                            attachments: [], // TODO: implement actual attachment feature
                          },
                          user.preferredPharmacyLprId,
                        );
                        methods.reset();
                      }
                    })}
                    hierarchy="primary"
                    logger={{ id: 'new-message-bottom-sheet-send-button' }}
                    style={styles.button}
                  >
                    {getText('send')}
                  </Button>
                </View>
              </View>
            </Form.Actions>
          </Form>
          <StoreSelector
            ref={storeSelectorRef}
            options={stores}
            defaultOption={stores.find(
              (x) => x.id === user?.preferredPharmacyLocationId,
            )}
            onChange={handleOnLocationChanged}
            storeInformationShown={false}
          />
        </View>
      }
    />
  );
};

export type NewMessageBottomSheetProps = NativeStackScreenProps<
  ConversationStackParamList,
  'new-message'
>;

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginLeft: theme.getSpacing(4),
  },
  bottomSheetViewTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  locationContainer: {
    flexDirection: 'row',
  },
  closeIcon: {
    justifyContent: 'flex-end',
  },
  container: {
    paddingTop: getSpacing(1),
  },
  buttonContainer: {
    flex: 1,
  },
  button: {
    marginBottom: getSpacing(1),
    marginTop: getSpacing(3),
  },
  actionsContainer: {
    flexDirection: 'row',
    paddingBottom: getSpacing(4),
    paddingTop: getSpacing(4),
  },
  actionsInnerContainer: {
    flex: 1,
    paddingRight: getSpacing(2),
  },
  radioButtonsContainer: {
    paddingTop: getSpacing(2),
    flexDirection: 'row',
  },
  radioButtonsInnerContainerLeft: {
    flex: 1,
    flexDirection: 'column',
  },
  radioButtonsInnerContainerRight: {
    flex: 1,
    flexDirection: 'column',
    height: '50%',
  },
  storeInfo: {
    paddingBottom: getSpacing(3),
    paddingLeft: getSpacing(1),
  },
  messageLimitWrapper: {
    width: '100%',
    alignItems: 'flex-end',
    marginTop: getSpacing(0.5),
    marginBottom: getSpacing(3),
  },
  messageLimit: {
    color: theme.palette.gray[500],
  },
}));

export const NewMessageBottomSheet = forwardRef<
  NewMessageHandler,
  NewMessageProps
>(NewMessageBottomSheetComponent);
