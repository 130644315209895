import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { ListItemLink, ListMenu } from 'assets/components/list-menu';
import { Text } from 'assets/components/text/Text';
import { ScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { GestureResponderEvent, Platform, View } from 'react-native';
import { Divider } from 'react-native-paper';
import { PrescriptionCard } from '../../../components/prescription-card';
import { StoreSelector } from '../../../components/store-selector';
import { MapSize } from '../../../components/store-selector/types';
import { useOrderRefillStore } from './order-store';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import {
  OrderItemDto,
  OrderStatus,
} from '@digitalpharmacist/order-service-client-axios';
import { useAppStateStore } from '../../../store/app-store';
import { NativeHeader } from '../../../../../../packages/assets/components/native-header';
import { CloseIcon } from '../../../../../../packages/assets/icons';
import { useUserState } from '../../../store/user-store';
import { useRefillMedicationsStore } from '../../refill/refill-store';
import { StackHeaderProps } from '@react-navigation/stack';
import refillService from '../../refill/refill-service';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { usePatientRecordState } from '../../account/patient/patient-store';

export const OrderDetails: FunctionComponent<
  PropsWithChildren<OrderDetailsProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const {
    orderRefill,
    selectedPatient,
    patientOrders,
    selectedPatientRecord: patientRecord,
    submitRefill,
  } = useOrderRefillStore();
  const { stores, pharmacyId, getStores } = useAppStateStore();
  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();
  const { user } = useUserState();
  const { updateMedicationsData } = useRefillMedicationsStore();
  const [otherInformationText, setOtherInformationText] = useState<string>('');

  const selectedPatientOrder = patientOrders?.find(
    (patientOrder) => patientOrder.order.order_id === orderRefill?.order_id,
  );

  useEffect(() => {
    if (!stores.length) void getStores();
  }, []);

  useEffect(() => {
    if (!orderRefill?.location_id) return;

    const selectedStore = stores.find(
      (store) => store.id === orderRefill.location_id,
    );
    setSelectedStore(selectedStore);
  }, [stores]);

  useEffect(() => {
    void loadOtherInformationText();
  }, [patientRecord]);

  const loadOtherInformationText = async () => {
    if (!patientRecord) return;

    setOtherInformationText(getText('loading'));

    try {
      const otherInformationText =
        await refillService.getPatientMedicalInfoText(patientRecord);

      setOtherInformationText(otherInformationText);
    } catch (error) {
      setOtherInformationText('Error ' + getText('loading'));
    }
  };

  const handleBack = (event: GestureResponderEvent) => {
    navigation.navigate('order');
  };

  const handleOnPress = async () => {
    if (
      !user?.preferredPharmacyLocationId ||
      !user.patientRecordId ||
      !user.id ||
      !orderRefill ||
      !submitRefill ||
      !updateMedicationsData
    )
      return;

    const refillItems = [];
    for (const ri of orderRefill.refill_items) {
      refillItems.push({
        item_name: ri.rx_number,
        item_description: ri.rx_name,
      } as OrderItemDto);
    }

    await submitRefill(
      pharmacyId,
      user.preferredPharmacyLocationId,
      orderRefill.patient_id,
      orderRefill.fulfillment_method,
      refillItems,
      user.id,
    );

    let selectedMeds = [];
    for (const ri of orderRefill.refill_items) {
      selectedMeds.push({
        ...ri.prescription,
      } as PrescriptionDto);
    }

    updateMedicationsData({
      selectedLocationId: user.preferredPharmacyLocationId,
      selectedMedications: selectedMeds,
      selectedPatient: selectedPatient,
      selectedPatientRecord: patientRecord,
    });

    navigation.navigate('refill', { screen: 'refill-review' });
  };

  return (
    <>
      <NativeHeader
        title={getText('order-details')}
        actionLabel={getText('reorder')}
        onActionPress={handleOnPress}
        onBack={handleBack}
        showAction={false}
        showActionText={true}
        showBack={true}
      />
      <ScreenContainer>
        <View>
          <View style={{ marginTop: theme.getSpacing(2) }}>
            <Text style={styles.textTitle}>{getText('medications')}</Text>
          </View>
          {orderRefill &&
            orderRefill.refill_items.map((item) => {
              if (!item.prescription) return;

              // TODO: KLAJD review  this filter make no sense here performance problem can happen
              const orderItem = selectedPatientOrder?.order.items?.find(
                (oi) => item.rx_number === oi.item_name,
              );

              return (
                <View key={item.rx_number}>
                  <View style={{ paddingVertical: theme.getSpacing(2) }}>
                    <Divider />
                  </View>
                  <PrescriptionCard
                    key={item.rx_number}
                    patient={selectedPatient}
                    prescription={item.prescription}
                    orderStatus={orderItem?.order_status}
                    selectable={false}
                  />
                </View>
              );
            })}
        </View>
        <View>
          <View style={styles.container}>
            <View style={{ paddingVertical: theme.getSpacing(1) }}>
              <Text style={styles.textTitle}>{getText('order-details')}</Text>
              <Divider />
            </View>
            <View style={styles.container}>
              {selectedStore && (
                <StoreSelector
                  options={stores}
                  defaultOption={selectedStore}
                  mapProps={{ size: MapSize.md }}
                  changeButtonShown={false}
                />
              )}
            </View>
          </View>
          <Divider />
          <ListMenu style={styles.container}>
            <ListItemLink showIcon={false}>
              <Text style={styles.textTitle}>
                {getText('other-information')}
              </Text>
              <Text>{otherInformationText}</Text>
            </ListItemLink>
            <ListItemLink showIcon={false}>
              <Text style={styles.textTitle}>{getText('method')}</Text>
              <Text style={{ textTransform: 'capitalize' }}>
                {orderRefill?.fulfillment_method}
              </Text>
            </ListItemLink>
          </ListMenu>
        </View>
        <View style={styles.noteForPharmacyContainer}>
          <Text style={styles.noteForPharmacyTitle}>
            {getText('note-for-pharmacy-not-optional')}
          </Text>
          <Text>{orderRefill?.notes}</Text>
        </View>
      </ScreenContainer>
    </>
  );
};

export type OrderDetailsProps = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  noteForPharmacyTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginBottom: theme.getSpacing(1),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginVertical: theme.getSpacing(1),
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
    justifyContent: 'space-between',
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  noteForPharmacyContainer: {
    borderWidth: 1,
    borderRadius: theme.roundness,
    borderColor: theme.palette.gray[100],
    backgroundColor: theme.palette.gray[100],
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.getSpacing(1),
    marginVertical: theme.getSpacing(1),
  },
}));
